var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed top-0 left-0 h-full w-full z-50"},[_c('div',{staticClass:"completed-modal opacity-0 absolute h-full w-full bg-black z-0"}),_c('div',{staticClass:"completed-content opacity-0 scale-75 transform h-full w-full relative z-10 flex items-center justify-center"},[_c('div',{staticClass:"completed-content-inner w-2/3 px-4 py-8 bg-white rounded border border-gray-200 shadow-xl flex flex-col items-center justify-center"},[_c('img',{staticClass:"w-1/3",attrs:{"src":require("@/assets/img/tutorialComplete.png")}}),_c('div',{staticClass:"w-3/4 text-center"},[_c('p',{staticClass:"text-lg mb-3"},[_vm._v("Nice "+_vm._s(_vm.user.firstName)+"! 🎉 You have completed the "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.tutorial.title))]),_vm._v(" tutorial "),_c('rewardSvg',{staticClass:"h-6 inline"})],1),_c('p',{staticClass:"text-lg"},[_vm._v("How useful was this tutorial?")]),_c('div',{staticClass:"w-full flex flex-col items-center justify-center"},[_c('div',{staticClass:"flex flex-row items-center justify-center my-4"},_vm._l((_vm.ratingPoints),function(point,idx){return _c('starSvg',{key:idx,staticClass:"h-10 cursor-pointer fill-current text-gray-400",class:{
                                'text-yellow-500': _vm.ratingPointsSelected >= point,
                            },on:{"mouseenter":() => _vm.ratingPointsSelected = point,"click":() => _vm.ratingPointsSelected = point}})}),1),_c('div',{staticClass:"w-3/5 flex flex-col items-center justify-center"},[_c('div',{staticClass:"w-full"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.ratingMessage),expression:"ratingMessage",modifiers:{"trim":true}}],staticClass:"appearance-none block bg-gray-100 text-gray-800 w-full resize-none rounded border border-gray-400 py-3 px-4 mb-8 h-24 leading-tight focus:outline-none focus:border-gray-500",attrs:{"placeholder":"Any additional feedback?"},domProps:{"value":(_vm.ratingMessage)},on:{"input":function($event){if($event.target.composing)return;_vm.ratingMessage=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"flex justify-around"},[_c('button',{staticClass:"transition duration-200 ml-3 bg-transparent ease-in-out text-gray-600 hover:text-gray-800 text-sm py-2 px-4 rounded",class:{
                                    'opacity-50 cursor-wait': _vm.isRatingTutorial,
                                },on:{"click":() => !_vm.isRatingTutorial && _vm.doDismissRateTutorial()}},[_vm._v(" Dismiss ")]),_c('button',{staticClass:"transition duration-200 ml-3 bg-orange-500 hover:bg-orange-700 ease-in-out font-semibold text-sm text-white py-2 px-4 rounded shadow-sm",class:{
                                    'opacity-50 cursor-wait': _vm.isRatingTutorial,
                                },on:{"click":() => !_vm.isRatingTutorial && _vm.doRateTutorial()}},[_vm._v(" "+_vm._s(_vm.isRatingTutorial ? 'Submitting...' : 'Submit')+" ")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
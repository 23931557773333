<template>
    <div class="fixed top-0 left-0 h-full w-full z-50">
        <div class="completed-modal opacity-0 absolute h-full w-full bg-black z-0"></div>
        <div class="completed-content opacity-0 scale-75 transform h-full w-full relative z-10 flex items-center justify-center">
            <div class="completed-content-inner w-2/3 px-4 py-8 bg-white rounded border border-gray-200 shadow-xl flex flex-col items-center justify-center">
                <img class="w-1/3" src="@/assets/img/tutorialComplete.png" />
                <div class="w-3/4 text-center">
                    <p class="text-lg mb-3">Nice {{ user.firstName }}! 🎉 You have completed the <span class="font-bold">{{ tutorial.title }}</span> tutorial <rewardSvg class="h-6 inline"/></p>
                    <p class="text-lg">How useful was this tutorial?</p>
                    <div class="w-full flex flex-col items-center justify-center">
                        <div class="flex flex-row items-center justify-center my-4">
                            <starSvg
                                v-for="(point, idx) in ratingPoints"
                                :key="idx"
                                class="h-10 cursor-pointer fill-current text-gray-400"
                                @mouseenter="() => ratingPointsSelected = point"
                                @click="() => ratingPointsSelected = point"
                                :class="{
                                    'text-yellow-500': ratingPointsSelected >= point,
                                }"
                            />
                        </div>
                        <div class="w-3/5 flex flex-col items-center justify-center">
                            <div class="w-full">
                                <textarea
                                    class="appearance-none block bg-gray-100 text-gray-800 w-full resize-none rounded border border-gray-400 py-3 px-4 mb-8 h-24 leading-tight focus:outline-none focus:border-gray-500"
                                    placeholder="Any additional feedback?"
                                    v-model.trim="ratingMessage">
                                </textarea>
                            </div>
                            <div class="flex justify-around">
                                <button
                                    @click="() => !isRatingTutorial && doDismissRateTutorial()"
                                    class="transition duration-200 ml-3 bg-transparent ease-in-out text-gray-600 hover:text-gray-800 text-sm py-2 px-4 rounded"
                                    :class="{
                                        'opacity-50 cursor-wait': isRatingTutorial,
                                    }">
                                    Dismiss
                                </button>
                                <button
                                    @click="() => !isRatingTutorial && doRateTutorial()"
                                    class="transition duration-200 ml-3 bg-orange-500 hover:bg-orange-700 ease-in-out font-semibold text-sm text-white py-2 px-4 rounded shadow-sm"
                                    :class="{
                                        'opacity-50 cursor-wait': isRatingTutorial,
                                    }">
                                    {{ isRatingTutorial ? 'Submitting...' : 'Submit' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixin from './mixin';

export default {
    name: 'TutorialCompleted',

    mixins: [mixin],

    props: {
        tutorial: {
            type: Object,
            required: true,
        },

        user: {
            type: Object,
            required: true,
        },

        isRatingTutorial: {
            type: Boolean,
            required: true,
        },

        rateTutorial: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            ratingPoints: [1, 2, 3, 4, 5],
            ratingPointsSelected: 0,
            ratingMessage: null,
            submittingFeedback: false,
        };
    },

    mounted() {
        this.playTutorialCompletedAnimations();
    },

    methods: {
        async doRateTutorial() {
            if (this.ratingPointsSelected === 0) return;

            await this.rateTutorial({
                // eslint-disable-next-line no-underscore-dangle
                tutorialId: this.tutorial._id,
                data: {
                    runtime: this.tutorial.userData.runtime,
                    rating: this.ratingPointsSelected,
                    message: this.ratingMessage,
                },
            });
            this.$emit('go-to-tutorial');
        },

        doDismissRateTutorial() {
            this.$emit('go-to-tutorial');
        },
    },

    components: {
        rewardSvg: () => import('@/assets/img/reward.svg'),
        starSvg: () => import('@/assets/img/star.svg'),
    },
};
</script>

<style lang="postcss" scoped>
.completed-content-inner {
    border: 5px solid;
    border-color: theme('colors.orange.500');
}
</style>
